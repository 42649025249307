import React, { useState, useEffect } from 'react';
import styles from './Venues.module.css'; // Import css modules stylesheet as styles
// import { Link } from 'react-router-dom';
import { baseUrl } from '../../App';

const VenueButton = ({ venue }) => {
    return null
    // return (
    //     <Link className={styles["venue-card-button"]} to={`venuedetails/${venue.slug}`}>
    //         Toon events
    //     </Link>
    // )
}

const Venues = () => {
    const [error, setError] = useState(null)
    const [venues, setVenues] = useState([]) // useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        fetch(`${baseUrl}/locations/`)
            .then(res => res.json())
            .then(
                (result) => {
                    setVenues(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [])

    if (error) {
        return (
            <div className="container py-5">
                <div className="alert alert-danger" role="alert">
                    <div>Error: {error.message}</div>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <></>
        )
    } else {
        return (
            <div className="container py-5">
                <h3 className="font-weight-bold">Andere venues ({venues.count})</h3>
                <p className="text-muted">Klik op een venue om de komende events te bekijken</p>
                <div className="row">
                    {venues.results.map((venue) =>
                        <div key={venue.id} className="col-md-6 col-lg-4 col-xl-4 py-2">
                            <div className="card rounded-0 border-0 h-100">
                                <div style={{ position: 'relative' }}>
                                    <img className="card-img-top mb-0 rounded-0" src={venue.cover_image} alt="Card cap"></img>
                                    <div className="card-img-overlay h-100 d-flex flex-column justify-content-end" style={{ background: 'linear-gradient(to top, black, 20%, transparent)', color: 'white' }}>
                                        <h5 className="card-title">{venue.name}</h5>
                                    </div>
                                </div>
                                <div className={`card-body ${styles["card-body-venues"]}`}>
                                    <p className="card-text" style={{ textAlign: 'left', padding: '20px' }}>
                                        {venue.description}
                                    </p>
                                </div>
                                {/* <div className={styles["venue-card-button"]}>Toon events</div> */}
                                {/* <Link className={styles["venue-card-button"]} to={`venuedetails/${venue.slug}`}>
                                    Toon events
                                </Link> */}
                                <VenueButton venue={venue} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default Venues