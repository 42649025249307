import React from 'react'
import { Link } from 'react-router-dom';
import styles from './CamperDetails.module.css'; // Import css modules stylesheet as styles
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import "moment/locale/nl";
import { baseUrl } from '../../App';

class CamperDetails extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            days: 0,
            x: null,
            y: null,
            isLoaded: false,
            error: null,
            startDate: null,
            endDate: null,
            event: {},
            blockedDays: ['dinsdag', 'woensdag', 'donderdag'], // ['yyyy-mm-dd', '2021-10-24']
            includesBlockedDays: false
        }
    }

    componentDidMount = () => {
        const slug = this.props.match.params.slug
        fetch(`${baseUrl}/campers/${slug}/`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        event: result
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    // Function thats called as DATERANGEPICKER property.
    // Takes a day from the DATERANGEPICKER as argument.
    // Het argument DAY word automatisch meegegeven door de DATERANGEPICKER component.
    // DAY is een momentjs object. Hierop kan je verschillende methodes uitvoeren.
    // Als een DAY matched met een waarde in BLOCKEDDAYS dan word TRUE gereturned, and is die DAY blocked.
    isBlocked = day => {
        console.log('day = ', day.format('dddd'))
        return this.state.blockedDays.includes(day.format('dddd'))
        // return this.state.blockedDays.includes(day.format('YYYY-MM-DD')) // Gebruik dit om specifieke data te blocken
    }

    // Function that returns an array of the dates within the selected range.
    daysBetweenDates = () => {
        let startDate = this.state.startDate.clone()
        let endDate = this.state.endDate.clone()
        const dates = []
        while (startDate.isSameOrBefore(endDate)) {
            dates.push(startDate.format('YYYY-MM-DD'));
            startDate.add(1, 'days');
        }
        return dates
    }

    // Function that checks if the date selection contains any of the blocked dates.
    checkDateSelection = () => {
        const dates = this.daysBetweenDates()
        for (let i = 0; i < dates.length; i++) {
            if (this.state.blockedDays.includes(dates[i])) {
                alert('Blocked date is included in selection! Please select a range without a blocked date.')
                this.setState({ includesBlockedDays: true, startDate: null, endDate: null })
                break;
            }
        }
        if (this.state.includesBlockedDays) {
            this.setState({ includesBlockedDays: false })
        }
        // if for loop completed without breaking set includesblockeddays to false.
    }

    render() {
        const { error, isLoaded, event } = this.state;
        if (error) {
            return <div className="container">Error: {error.message}</div>
        } else if (!isLoaded) {
            return <></>
        } else {
            return (
                <div className="container mb-5">

                    <div style={{ backgroundColor: 'var(--joepark-grey)' }}>
                        <div className="container" style={{ marginBottom: '130px', maxHeight: '265px' }}>
                            <div className={`jumbotron ${styles['jumbotron-eventdetails']} text-white mb-0`} style={{ backgroundImage: `linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4)), url(${event.cover_image})` }}>
                                <header>
                                    <h1 className="display-4 font-weight-bold">
                                        {event.name}
                                    </h1>
                                </header>
                            </div>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 py-2">
                                <h2 className='font-weight-bold text-left'>Omschrijving</h2>
                                <p className="text-left">{event.description}</p>
                            </div>
                            <div className="col-md-6 py-2">
                                <h2 className='font-weight-bold text-left mb-0'>Datum kiezen</h2>
                                <p className="text-muted">
                                    Beschikbare camperplaatsen <span className="badge badge-dark">{event.stock[0].quantity}</span>
                                </p>
                                <DateRangePicker
                                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    onDatesChange={({ startDate, endDate }) => {
                                        return this.setState({ startDate, endDate },
                                            () => {
                                                if (this.state.startDate != null && this.state.endDate != null) {
                                                    this.setState({ x: this.state.startDate.format(), y: this.state.endDate.format() })
                                                    this.setState({ days: this.state.endDate.diff(this.state.startDate, 'days') })
                                                    this.checkDateSelection()
                                                }
                                            }
                                        )
                                    }}
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                    isDayBlocked={this.isBlocked}
                                    startDatePlaceholderText="Aankomst"
                                    endDatePlaceholderText="Vertrek"
                                    numberOfMonths={1}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <Link
                                    to={{
                                        pathname:
                                            `/camperoverview/${this.state.event.slug}/${this.state.event.stock[0].id}`,
                                        state: {
                                            startDate: this.state.x,
                                            endDate: this.state.y,
                                            days: this.state.days,
                                            event: this.state.event
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <button type="button" className={`btn btn-info btn-lg ${styles['btn-eventdetails']}`} disabled={!this.state.days || this.state.includesBlockedDays}>Boek nu je ticket</button>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default CamperDetails