import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from './images/logo.svg'; // Tell webpack this JS file uses this image
import styles from './Navigation.module.css'; // Import css modules stylesheet as styles
import { Link } from "react-router-dom";

const Navigation = () => (
    <>
        <Navbar className={styles['bg-navbar-image']} expand="lg">
            <div className="container">
                <Navbar.Brand href="/">
                    <img src={logo} width='100px' alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto font-weight-bold">
                        <Link className="text-white nav-link" to="/venues">venues</Link>
                        <Link className="text-white nav-link" to="/events">events</Link>
                        {/* <Link className="text-white nav-link" to="/camperdetails/joe-camper-teleport-hotel">campers</Link> */}
                        <Link className="text-white nav-link" to="/crew">crew</Link>
                        <Link className="text-white nav-link" to="/locations">locations</Link>
                        <Link className="text-white nav-link" to="/contact">contact</Link>
                        <Link className="text-white nav-link" to="/faq">faq</Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    </>
)

export default Navigation