import React from 'react'

const OverOns = () => {
    return (
        <div className="container py-5">
            <h3 className="font-weight-bold">
                Over Ons
            </h3>
        </div>
    )
}

export default OverOns