import React from 'react';
import { IdealBankElement, CardElement } from '@stripe/react-stripe-js';
import styles from '../CamperCheckoutForm/CamperCheckoutForm.module.css'; // Import css modules stylesheet as styles
import IdealForm from '../IdealForm';
import CreditcardForm from '../CreditcardForm';
import { baseUrl } from '../../App';

class EventCheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // personal details
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            // ajax call
            isLoadedPi: false,
            errorPi: null,
            // payment
            pi: {},
            payByIdeal: true,
            payByCreditcard: false,
            payByBancontact: false,
            succeeded: false,
            error: null,
            processing: '',
            disabled: true
        };
        this.getPI = this.getPI.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        this.setState({ 
            disabled: event.empty, 
            error: event.error ? event.error.message: ""
        })
    }

    componentDidMount = () => {
        this.getPI()
    }

    getPI() {
        const { event, id, quantity, password } = this.props.props.location.state
        fetch(`${baseUrl}/tickets/init/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                quantity: quantity,
                stock_type: event.stock.find(x => x.id === parseInt(id)).id,
                password: password
            })
        })
        .then(response => {
            if (response.ok === true) {
                console.log('ok')
                return response.json()
            }
            else if (!response.ok && response.status === 401) {
                throw new Error('Invalid password');
            }
            else {
                throw new Error('Network error')
            }
        })
        .then(data => {
            this.setState({
                isLoadedPi: true,
                pi: data
            })
        })
        .catch(error => {
            console.log('error = ', error)
            this.setState({
                isLoadedPi: true,
                errorPi: error
            })
        })
    }

    async sendBillingDetails(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return response.json()
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        const { firstName, lastName, phone, email, pi, payByIdeal, payByCreditcard, payByBancontact } = this.state
        this.setState({ processing: true })
        this.sendBillingDetails(`${baseUrl}/tickets/complete/`, {
            first_name: firstName,
            last_name: lastName,
            cell_phone: phone,
            email: email,
            stripe_intent_id: pi.id
        })

        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (payByIdeal) {
            const idealBank = elements.getElement(IdealBankElement);

            const { error } = await stripe.confirmIdealPayment(pi.client_secret, {
                payment_method: {
                    ideal: idealBank,
                    billing_details: {
                        name: `${firstName} ${lastName}`,
                        phone,
                        email
                    },
                },
                // return_url: 'http://127.0.0.1:3000/checkout-complete',
                return_url: 'https://joepark.nl/checkout-complete/',
            });

            if (error) {
                // Show error to your customer.

                this.setState({ 
                    error: `Payment failed ${error.message}`,
                    processing: false 
                })
            }

            // Otherwise the customer will be redirected away from your
            // page to complete the payment with their bank.
            this.setState({
                error: null,
                processing: false,
                succeeded: true
            })
        }

        if (payByCreditcard) {
            const result = await stripe.confirmCardPayment(pi.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: `${firstName} ${lastName}`,
                    },
                }
            });

            if (result.error) {
                // Show error to your customer (e.g., insufficient funds)
                this.setState({
                    error: `Payment failed ${result.error.message}`,
                    processing: false
                })                
                alert(result.error.message)
            } else {
                // The payment has been processed!
                if (result.paymentIntent.status === 'succeeded') {
                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.
                    this.setState({
                        error: null,
                        processing: false,
                        succeeded: true
                    })
                    this.props.props.history.push('/checkout-complete')
                }
            }
        }

        if (payByBancontact) {
            // Redirects away from the client
            const result = await stripe.confirmBancontactPayment(pi.client_secret, {
                payment_method: {
                    billing_details: {
                        name: `${firstName} ${lastName}`
                    }
                },
                // return_url: 'http://127.0.0.1:3000/checkout-complete',
                return_url: 'https://joepark.nl/checkout-complete/',
            })
            if (result.error) {
                // Inform the customer that there was an error.
                this.setState({
                    error: `Payment failed ${result.error.message}`,
                    processing: false
                })
                alert(result.error)
            }
            else {
                this.setState({
                    error: null,
                    processing: false,
                    succeeded: true
                })
            }
        }
    }

    render() {
        const { firstName, lastName, phone, email, payByIdeal, payByCreditcard, payByBancontact, succeeded, error, processing, disabled, isLoadedPi, errorPi } = this.state;
        const { stripe } = this.props

        if (errorPi) {
            return (
                <div className="container py-5">
                    <div className="alert alert-danger" role="alert">
                        <div>Error: {errorPi.message}</div>
                    </div>
                    <p>Druk <button onClick={this.props.props.history.goBack} className={`${styles['link-button']} alert-link`}>hier</button> om terug te keren naar de vorige pagin om vervolgens een juist wachtwoord op te geven.</p>
                </div>
            )
          } else if (!isLoadedPi) {
                return <></>
          } else {
            let payByform;
            if (payByIdeal) {
                payByform = <IdealForm stripe={stripe} handleChange={this.handleChange} processing={processing} disabled={disabled} succeeded={succeeded} error={error} />
            }
            if (payByCreditcard) {
                payByform = <CreditcardForm stripe={stripe} handleChange={this.handleChange} processing={processing} disabled={disabled} succeeded={succeeded} error={error} />
            }
            if (payByBancontact) {
                payByform = <button id="submit" className={styles["btn-checkout"]}>Pay</button>
            }
            return (
                <div className="container py-5" style={{ maxWidth: '960px'}}>
                    <h4 className="mb-3">Vul hier uw betaalgegevens in</h4>
                        <form id="payment-form" onSubmit={this.handleSubmit} style={{ margin: '0 auto'}}>
                            <fieldset>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Voornaam</label>
                                    <input
                                        className={`form-control`}
                                        id="firstName"
                                        placeholder="Voornaam"
                                        value={firstName}
                                        onChange={(event) => {
                                            this.setState({ firstName: event.target.value });
                                        }}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Achternaam</label>
                                    <input
                                        className={`form-control`}
                                        id="lastName"
                                        placeholder="Achternaam"
                                        value={lastName}
                                        onChange={(event) => {
                                            this.setState({ lastName: event.target.value });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label >Telefoonnummer</label>
                                    <input
                                        className={`form-control`}
                                        id="phone"
                                        placeholder="Telefoonnummer"
                                        value={phone}
                                        onChange={(event) => {
                                            this.setState({ phone: event.target.value });
                                        }}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label >E-mailadres</label>
                                    <input
                                        className={`form-control`}
                                        id="email"
                                        placeholder="E-mailadres"
                                        value={email}
                                        onChange={(event) => {
                                            this.setState({ email: event.target.value });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label style={{ display: 'block' }}>Betaalmethode</label>
                                <div className="btn-group" role="group">
                                    <button type="button" className={payByIdeal ? "btn btn-outline-secondary active" : "btn btn-outline-secondary disabled"} onClick={() => this.setState({ payByIdeal: true, payByCreditcard: false, payByBancontact: false })}>Ideal</button>
                                    <button type="button" className={payByCreditcard ? "btn btn-outline-secondary active" : "btn btn-outline-secondary disabled"} onClick={() => this.setState({ payByIdeal: false, payByCreditcard: true, payByBancontact: false })}>Card</button>
                                </div>
                            </div>
                            {payByform}
                            </fieldset>
                        </form>
                </div>
            )
          }
    }
}

export default EventCheckoutForm