import React, { useState } from 'react'
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react'
import styles from './ParkingLocations.module.css'

const parkingLocations = [
    {
        name: "Joe Park - P1",
        street: "Burgemeester Stramanweg 130",
        postalCode: "1101 EP Amsterdam",
        url: "https://go.maps.link/joepark/p1",
        initialCenter: {
            lat: 52.31395184739302,
            lng: 4.940552041244909
        },
        id: 1
    },
    {
        name: "Joe Park - P2",
        street: "Duivendrecht NL, Borchlandweg",
        postalCode: "1099 CT Amsterdam",
        url: "https://go.maps.link/joepark/p2",
        initialCenter: {
            lat: 52.31851749561181,
            lng: 4.931331862916737
        },
        id: 2
    },
    {
        name: "Joe Park - P3",
        street: "De Entree 228",
        postalCode: "1101 EE Amsterdam",
        url: "https://go.maps.link/joepark/p3",
        initialCenter: {
            lat: 52.313116168724974,
            lng: 4.94128822321084
        },
        id: 3
    },
    {
        name: "Joe Park - P4",
        street: "De Entree 7",
        postalCode: "1101 BH Amsterdam",
        url: "https://go.maps.link/joepark/p4",
        initialCenter: {
            lat: 52.31374583110482,
            lng: 4.939602131274848
        },
        id: 4
    },
    {
        name: "Joe Park - P5",
        street: "De Entree 7",
        postalCode: "1101 BH Amsterdam",
        url: "https://go.maps.link/joepark/p5",
        initialCenter: {
            lat: 52.31460085762626,
            lng: 4.938915488256946
        },
        id: 5
    },
    {
        name: 'Joe Park - P7',
        street: "Haaksbergweg 3-73",
        postalCode: "1101 BP Amsterdam",
        url: "https://go.maps.link/joepark/p7",
        initialCenter: {
            lat: 52.310674134389906,
            lng: 4.9420655765537145
        },
        id: 6
    },
    {
        name: "Joe Park - P10",
        street: "Herikerbergweg 288",
        postalCode: "1101 CT Amsterdam",
        url: "https://go.maps.link/joepark/p10",
        initialCenter: {
            lat: 52.30944295964569,
            lng: 4.940601577671542
        },
        id: 7
    },
    {
        name: "Joe Park - P13",
        street: "Hullenbergweg 6",
        postalCode: "1101 BL Amsterdam",
        url: "https://go.maps.link/joepark/p13",
        initialCenter: {
            lat: 52.30465411317231,
            lng: 4.951264815038999
        },
        id: 8
    },
    {
        name: "Joe Park - P18",
        street: "Fraijlemaborg 131",
        postalCode: "1102 CV Amsterdam",
        url: "https://go.maps.link/joepark/p18",
        initialCenter: {
            lat: 52.30944295964569,
            lng: 4.940601577671542
        },
        id: 9
    },
    {
        name: 'Joe Park - Amstel III',
        street: "Hoogoorddreef 72",
        postalCode: "1011 BG Amsterdam",
        url: "https://go.maps.link/joepark/amsteliii",
        initialCenter: {
            lat: 52.30835932515154,
            lng: 4.937455134841846
        },
        id: 10
    },
    {
        name: 'Joe Park - P Dome',
        street: "De Passage 88",
        postalCode: "1101 AX Amsterdam",
        url: "https://go.maps.link/joepark/pdome",
        initialCenter: {
            lat: 52.31497501562556,
            lng: 4.938839767527273
        },
        id: 11
    },
    {
        name: 'Joe Park - P Mediarena',
        street: "MediArena 5-8",
        postalCode: "1040 KB Amsterdam",
        url: "https://go.maps.link/joepark/mediarena",
        initialCenter: {
            lat: 52.31476679649569,
            lng: 4.936117442326897
        },
        id: 12
    }
]

const ParkingLocation = ({ googleData, parkeerLocation }) => {
    const [showingInfoWindow, setShowingInfoWindow] = useState(false)
    const [activeMarker, setactiveMarker] = useState({})
    const [selectedPlace, setselectedPlace] = useState({})

    const onMarkerClick = (googleData, marker) => {
        setselectedPlace(googleData)
        setactiveMarker(marker)
        setShowingInfoWindow(true)
    }

    const onClose = () => {
        if (showingInfoWindow) {
            setShowingInfoWindow(false)
            setactiveMarker(null)
        }
    }

    return (
        <div className="row justify-content-around">
            <div className="col-md-7 col-lg-6 mb-3 mb-md-0">
                <div className={`w-100 rounded`} style={{ boxShadow: ".5rem .5rem 0 rgba(233,236,239,.5)" }}>
                    <Map
                        mapTypeControl={false}
                        streetViewControl={false}
                        fullscreenControl={false}
                        google={googleData.google}
                        zoom={14}
                        containerStyle={{ width: '100%', minHeight: '30vh', position: 'relative' }}
                        style={{ borderRadius: ".25rem" }}
                        initialCenter={parkeerLocation.initialCenter
                        }
                    >
                        <Marker
                            onClick={onMarkerClick}
                            name={parkeerLocation.name}
                        />
                        <InfoWindow
                            marker={activeMarker}
                            visible={showingInfoWindow}
                            onClose={onClose}
                        >
                            <div>
                                <h3>{selectedPlace.name}</h3>
                            </div>
                        </InfoWindow>
                    </Map>
                </div>
            </div>
            <div className="col-md-4 col-lg-3">
                <h2>{parkeerLocation.name}</h2>
                <p>
                    <a href={parkeerLocation.url}>{parkeerLocation.url}</a>
                </p>
                <div className="mb-3">
                        <h6 className="mb-1">Adres</h6>
                        <address>
                            {parkeerLocation.street}
                            <br></br>
                            {parkeerLocation.postalCode}
                        </address>
                </div>
            </div>
        </div>
    )
}

const ParkingLocations = (googleData) => {
    return (
        <>
            <div className={`container py-5 ${styles['stack']}`}>
                {
                    parkingLocations.map(parkeerLocation => {
                        return <ParkingLocation key={parkeerLocation.id} googleData={googleData} parkeerLocation={parkeerLocation} />
                    })
                }
            </div>
        </>
    )
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_API_KEY
})(ParkingLocations);