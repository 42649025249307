import React from 'react'
import faq from './faq.scss'

const Faq = () => {
    return (
        <div className="container py-5">
            <h3 className="font-weight-bold mb-4">
                Faq
            </h3>
            <div className="row">
                <div className="col-12 col-lg-8">

                    <div className="faq-wrapper">
                        <div className="tabs">
                            <div className="tab">
                                <input type="checkbox" id="chck1"></input>
                                <label className="tab-label" htmlFor="chck1">Hoe scan ik mijn ticket?</label>
                                <div className="tab-content">
                                    Op het parkeerticket staat een QR-code. Deze QR-code kan je gemakkelijk vanuit jouw telefoon laten scannen bij de slagboom van de door jouw gekozen parkeerlocatie.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck2"></input>
                                <label className="tab-label" htmlFor="chck2">Wat is het adres van mijn parkeerlocatie?</label>
                                <div className="tab-content">
                                    Op jouw parkeerticket staat bij welke parkeergarage je kan parkeren. <a href="https://www.joepark.nl/locations">Klik hier</a> (<a href="https://www.joepark.nl/locations">https://www.joepark.nl/locations</a>) voor meer info over de door jou gekozen parkeerlocatie.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck3"></input>
                                <label className="tab-label" htmlFor="chck3">Vanaf hoe laat kan ik inrijden?</label>
                                <div className="tab-content">
                                Doordeweeks en in het weekend is dit 3 uur voor aanvang van de show.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck4"></input>
                                <label className="tab-label" htmlFor="chck4">Tot wanneer kan ik uitrijden?</label>
                                <div className="tab-content">
                                Dit kan tot 2 uur na afloop van het evenement.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck5"></input>
                                <label className="tab-label" htmlFor="chck5">Ik heb mijn parkeerticket niet ontvangen? Wat nu?</label>
                                <div className="tab-content">
                                Stuur ons een Whatsapp bericht, dan helpen we je graag verder (<a href="https://api.whatsapp.com/send?phone=31638882552&text=Hallo%20Livecrowd!%20Ik%20heb%20een%20vraag%20over%20Joe%20Park%20%3A%20">Klik hier</a>).
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck6"></input>
                                <label className="tab-label" htmlFor="chck6">Kan ik de datum van mijn reservering veranderen?</label>
                                <div className="tab-content">
                                Dit kan alleen voor hetzelfde evenement. Stuur hier een mail voor naar <a href="mailto:info@joepark.nl">info@joepark.nl</a> Om sneller geholpen te worden stuur ons een <a className="bold-link" href="https://api.whatsapp.com/send?phone=31638882552&text=Hallo%20Livecrowd!%20Ik%20heb%20een%20vraag%20over%20Joe%20Park%20%3A%20">Whatsapp bericht hier</a>.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck7"></input>
                                <label className="tab-label" htmlFor="chck7">Kan ik bij jullie een parkeerticket kopen voor een tourbus?</label>
                                <div className="tab-content">
                                Dit wordt voor enkele specifieke shows beschikbaar gesteld.
                                </div>
                            </div>
                            <div className="tab">
                                <input type="checkbox" id="chck8"></input>
                                <label className="tab-label" htmlFor="chck8">Is het kenteken van mijn auto gekoppeld aan het parkeerticket?</label>
                                <div className="tab-content">
                                Nee, deze is niet gekoppeld aan jouw parkeerticket.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Faq