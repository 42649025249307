import React from 'react'

const Contact = () => {
    return (
        <div className="container py-5">
            <h3 className="font-weight-bold">
                Contact
            </h3>
            <h4>
                Joe Park
            </h4>
            <div>
                <h5>
                    KVK Amsterdam
                </h5>
                <p>
                    69178100
                </p>
            </div>
            <div>
                <h5>
                    BTW
                </h5>
                <p>
                    NL857769376B01
                </p>
            </div>
        </div>
    )
}

export default Contact