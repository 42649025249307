import React from 'react'
import bg_werkenbijjoe from './bg_werkenbijjoe.svg'
import styles from './WerkenBij.module.css'; // Import css modules stylesheet as styles

const WerkenBij = () => {
    return (
        <div className="container py-5">
            <div className={`jumbotron text-white mb-0 rounded-0 ${styles['jumbotron-werkenbij']}`} style={{ backgroundImage: `linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4)),url(${bg_werkenbijjoe})` }}>
                <header>
                    <h1 className="display-4 font-weight-bold">
                        Werken bij Joe Park
                    </h1>
                </header>
            </div>
            <div className="row pt-5">
                <div className="col">
                    <h2 className="font-weight-bolder">
                        PARKEREN BIJ JOE.
                    </h2>
                    <p>
                        Joe Park is een vernieuwend parkeerconcept, waarbij snelheid en gemak centraal staan. Een parkeerplaats op een steenworp afstand van de grootste evenemententerreinen vooraf reserveren. Dan ben je bij Joe Park aan het juiste adres.
                    </p>
                </div>
                <div className="col">
                    <h2 className="font-weight-bolder">
                        SAMEN WERKEN.
                    </h2>
                    <p>
                        Dat doen we graag, samen werken. Wij zijn zelfs een samenwerkingsverband van specialisten. Organiseer je evenementen of werkzaam bij de overheid? Dan ondersteunen wij graag in de ‘Customer Journey’.
                    </p>
                </div>
                <div className="col">
                    <h2 className="font-weight-bolder">
                        WERKEN BIJ JOE!
                    </h2>
                    <p>
                        Joe Park is een samenwerkingsverband van diverse partners met een grote hoeveelheid kennis op het gebied van verkeerssituaties en parkeergedrag. Wij verwelkomen iedereen die ons team wilt versterken.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WerkenBij