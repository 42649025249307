import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import styles from './CamperCheckoutForm/CamperCheckoutForm.module.css'; // Import css modules stylesheet as styles

const CARD_ELEMENT_OPTIONS = {
    hideIcon: true,
    hidePostalCode: true,
    style: {
        base: {
            color: "#32325D",
            fontWeight: 500,
            fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",

            "::placeholder": {
                color: "#CFD7DF"
            }
        },
        invalid: {
            color: "#E25950"
        }
    }
};

const CreditcardForm = ({ stripe, handleChange, processing, disabled, succeeded, error }) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="card-element">
                    Kaart informatie
                </label>
                <CardElement
                    id="card-element"
                    className={styles["StripeElement"]}
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <button disabled={processing || disabled || succeeded || !stripe} id="submit" className={styles["btn-checkout"]}>
                    <span id="button-text">
                        {processing ? (<div className={styles['spinner']} id="spinner"></div>) : ("Pay")}
                    </span>
                </button>

                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className={styles['card-error']} role="alert">
                        {error}
                    </div>
                )}

                {/* Show a success message upon completion */}
                <p className={succeeded ? `${styles["result-message"]}` : `${styles["result-message"]} ${styles["hidden"]}`}>
                    Payment succeeded...
                </p>
            </div>
        </>
    )
}

export default CreditcardForm