import React from 'react';
import { IdealBankElement } from '@stripe/react-stripe-js';
import styles from './CamperCheckoutForm/CamperCheckoutForm.module.css'; // Import css modules stylesheet as styles

const IDEAL_ELEMENT_OPTIONS = {
    classes: {
        base: 'StripeElementIdeal',
        focus: 'StripeElementIdeal--focus',
    },
    style: {
        base: {
            padding: '10px 14px',
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

const IdealForm = ({ stripe, handleChange, processing, disabled, succeeded, error }) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="ideal">iDEAL-bank</label>
                <IdealBankElement
                    className={styles["StripeElementIdeal"]}
                    id="ideal"
                    onChange={handleChange}
                    options={IDEAL_ELEMENT_OPTIONS}
                />
            </div>
            <div className="form-group">
                <button disabled={processing || disabled || succeeded || !stripe} id="submit" className={styles["btn-checkout"]}>
                    <span id="button-text">
                        {/* {processing ? (<div className="spinner" id="spinner"></div>) : ("Pay")} */}
                        {processing ? (<div className={styles['spinner']} id="spinner"></div>) : ("Pay")}
                    </span>
                </button>

                {/* Show any error that happens when processing the payment */}
                {error && (
                    // <div className="card-error" role="alert">
                    <div className={styles['card-error']} role="alert">
                        {error}
                    </div>
                )}

                {/* Show a success message upon completion */}
                {/* <p className={succeeded ? "result-message" : "result-message hidden"}> */}
                <p className={succeeded ? `${styles["result-message"]}` : `${styles["result-message"]} ${styles["hidden"]}`}>
                    Payment succeeded...
                </p>
            </div>
        </>
    )
}

export default IdealForm