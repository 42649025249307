import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import calendaricon from './images/calendaricon.svg'
import locationicon from './images/locationicon.svg'
import euroicon from './images/euroicon.svg'
import timeicon from './images/timeicon.svg'
import styles from './Events.module.css'
import { useLocation } from "react-router-dom"

const Events = ({ eventsUrl }) => {
    const location = useLocation()
    const [error, setError] = useState(null)
    const [events, setEvents] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    // Pass in events object
    var formatter = new Intl.DateTimeFormat('nl-NL', {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        // fractionalSecondDigits: 3,
        hour12: true,
        timeZone: 'UTC'
    })

    useEffect(() => {
        fetch(eventsUrl)
            .then(res => res.json())
            .then(
                (result) => {
                    setEvents(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [eventsUrl])

    if (error) {
        return <div className="container py-5">Error: {error.message}</div>
    } else if (!isLoaded) {
        return (
            <></>
        )
    } else {
        if (events.count === 0) {
            return (
                <div className="container py-5">
                    <h2 className="text-center font-weight-bolder">Geen aankomende events!</h2>
                </div>
            )
        }
        const currentDate = new Date()
        const results = events.results
        results.forEach(function (result, index) {
            result.dates = result.dates.filter(date => {
                const startDate = new Date(date.start_date)
                return startDate >= currentDate
            }) // verwijder verlopen data
            if (result.dates.length === 0) {
                results.splice(index, 1);
            } // van events die geen data meer over hebben (dus hele event is voorbij), verwijder het hele event
            result.dates.sort((i, j) => new Date(i.start_date) - new Date(j.start_date)) // sorteer data op eerstvolgende datum
        })
        return (
            <div className="container py-5">
                <h3 className="font-weight-bold">Aankomende events ({events.count})</h3>
                <p className="text-muted">Klik op een event om de parkeeractie te starten</p>
                {/* Learn about this code on MDN: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Realizing_common_layouts_using_CSS_Grid_Layout */}
                <ul className={styles["listing"]}>
                    {results.map(event =>
                        <li className={`card border-0 rounded-0 ${location.pathname === "/events" ? "bg--grey" : null}`} key={event.id}>
                            <Link to={`/eventdetails/${event.slug}`}>
                                <div style={{ position: 'relative' }}>
                                    <img src={event.cover_image} className={`card-img-top ${styles['card-img-top-custom']}`} alt="..."></img>
                                    <div className="card-img-overlay d-flex flex-column justify-content-end" style={{ background: 'linear-gradient(to top, black, transparent)', color: 'white' }}>
                                        <h5 className="card-title font-weight-bold" style={{ position: 'absolute', textShadow: '0 1px black' }}>{event.name}</h5>
                                    </div>
                                </div>
                                <ul className={`list-group list-group-flush ${styles['list-group-event']}`}>
                                    <li className={`list-group-item ${styles['list-group-item-event']}`}>
                                        <div className="d-flex flex-row">
                                            <div className="p-2 bd-highlight">
                                                <img
                                                    className={styles['event-icon']}
                                                    src={locationicon}
                                                    alt='location'
                                                />
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                {event.location}
                                            </div>
                                        </div>
                                    </li>
                                    <li className={`list-group-item ${styles['list-group-item-event']}`}>
                                        <div className="d-flex flex-row">
                                            <div className="p-2 bd-highlight">
                                                <img
                                                    className={styles['event-icon']}
                                                    src={calendaricon}
                                                    alt='date'
                                                />
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                {formatter.format(new Date(event.dates[0].start_date))}
                                            </div>
                                        </div>
                                    </li>
                                    <li className={`list-group-item ${styles['list-group-item-event']}`}>
                                        <div className="d-flex flex-row">
                                            <div className="p-2 bd-highlight">
                                                <img
                                                    className={styles['event-icon']}
                                                    src={euroicon}
                                                    alt='euro'
                                                />
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                {/* Generates array of prices, takes the lowest in the array, formats it as xx,xx */}
                                                {
                                                    (Math.min(...event.stock.map(i => i.price)) === Infinity)
                                                    ? "Geen tickets beschikbaar"
                                                    : Math.min(...event.stock.map(i => i.price)).toFixed(2)
                                                }*
                                            </div>
                                        </div>
                                    </li>
                                    <li className={`list-group-item ${styles['list-group-item-event']}`}>
                                        <div className="d-flex flex-row">
                                            <div className="p-2 bd-highlight">
                                                <img
                                                    className={styles['event-icon']}
                                                    src={timeicon}
                                                    alt='time'
                                                />
                                            </div>
                                            <div className="p-2 bd-highlight">
                                                {new Date(event.dates[0].start_date).getHours()}:{(new Date(event.dates[0].start_date).getMinutes() < 10 ? '0' : '') + new Date(event.dates[0].start_date).getMinutes()}{' '}uur
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Link>
                        </li>
                    )}
                </ul>
                <p className="text-muted text-center mt-4 mb-0">* Inclusief 21% BTW, exclusief servicekosten.</p>
            </div>
        )
    }
}

export default Events
