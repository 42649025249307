import React, { } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Home from './components/Home/Home'
import Navigation from './components/Navigation/Navigation'
import Footer from './components/Footer/Footer'
import Events from './components/Events/Events'
import Contact from './components/Contact/Contact'
import Faq from './components/Faq/Faq'
import Venues from './components/Venues/Venues'
import OverOns from './components/OverOns/OverOns'
import EventDetails from './components/EventDetails/EventDetails'
import EventOverview from './components/EventOverview/EventOverview';
import CamperDetails from './components/CamperDetails/CamperDetails';
import CamperOverview from './components/CamperOverview/CamperOverview';
import AlgemeneVoorwaarden from './components/AlgemeneVoorwaarden.js'
import InjectedCheckoutForm from './components/InjectedCheckoutForm';
import CheckoutComplete from './components/CheckoutComplete';
import Crew from './components/Crew/Crew'
import ParkingLocations from './components/ParkingLocations/ParkingLocations'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
const stripePromise = loadStripe('pk_live_LlOzcFbQkNFbyBPv72adiGHG00yiY69XKJ')

export const baseUrl = "https://admin.joepark.nl/api"

class App extends React.Component {
    render() {
        return (
            // Reason voor the Site class and Site-content class.
            // https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
            <div className="Site">
                <Router>
                    <Navigation />
                    <main className="Site-content">
                        <Switch>

                            <Route path="/checkout-complete">
                                <CheckoutComplete />
                            </Route>

                            <Route path="/algemene_voorwaarden">
                                <AlgemeneVoorwaarden />
                            </Route>

                            {/* Reason for using component={}. Without this way of syntax it seems that class modules cant pass props through links */}
                            <Route path='/camperdetails/:slug/' component={CamperDetails} />

                            <Route path='/camperoverview/:slug/:id' component={CamperOverview} />

                            <Route path="/eventdetails/:slug">
                                <EventDetails />
                            </Route>

                            <Route path="/eventoverview/:slug/:id">
                                <EventOverview />
                            </Route>

                            <Route path='/checkout/:slug/:id' render={(props) => <Elements {...props} stripe={stripePromise}> <InjectedCheckoutForm {...props} /> </Elements>} />

                            <Route path="/venues">
                                <Venues />
                            </Route>

                            <Route path="/events">
                                <Events eventsUrl={`${baseUrl}/events-list`} />
                            </Route>

                            <Route path="/overons">
                                <OverOns />
                            </Route>

                            <Route path="/contact">
                                <Contact />
                            </Route>
                            
                            <Route path="/faq">
                                <Faq />
                            </Route>

                            <Route path="/crew">
                                <Crew />
                            </Route>

                            <Route path="/locations">
                                <ParkingLocations />
                            </Route>

                            <Route path="/">
                                <Home />
                            </Route>

                        </Switch>
                    </main>
                    <Footer />
                </Router>
            </div>
        )
    }
}

export default App