import React from 'react';
import { Link } from 'react-router-dom';
import twittericon from './images/twittericon.svg'
import instagramicon from './images/instagramicon.svg'
import whatsappicon from './images/whatsappicon.svg'
import mailicon from './images/mailicon.svg'
import facebookicon from './images/facebookicon.svg'

const Footer = () => {
    return (
        <footer className="footer" style={{backgroundColor: '#f1f1f1'}}>
            <div className="container pt-5">
                <div className="row justify-content-between">
                    <div className="col-auto mb-3">
                        <ul className="nav" style={{ justifyContent: 'space-evenly', flexWrap: 'nowrap', alignItems: 'center' }}>
                            <li className="nav-item">
                                <a href='https://twitter.com/joeparknl' className="nav-link">
                                    <img src={twittericon} width='40rem' alt="Logo" className="p-1" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href='https://www.instagram.com/joeparknl/' className="nav-link">
                                    <img src={instagramicon} width='40rem' alt="Logo" className="p-1" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href='https://api.whatsapp.com/send?phone=31638882552&text=Hallo%20Livecrowd!%20Ik%20heb%20een%20vraag%20over%20Joe Park%20%3A%20' className="nav-link">
                                    <img src={whatsappicon} width='40rem' alt="Logo" className="p-1" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href='mailto: info@joepark.nl' className="nav-link">
                                    <img src={mailicon} width='40rem' alt="Logo" className="p-1" />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href='https://facebook.com/Joe-Park-483680252115786/' className="nav-link">
                                    <img src={facebookicon} width='40rem' alt="Logo" className="p-1" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-auto">
                        <p className="mb-0">
                            <Link to="/algemene_voorwaarden">Algemene voorwaarden</Link>
                        </p>
                        <p>Privacy</p>
                    </div>
                    <div className="col-auto">
                        <p className="mb-0 text-muted">© 2023 - Joepark B.V.</p>
                        <p className="text-muted">Alle rechten voorbehouden</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer