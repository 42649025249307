import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from "react-router-dom"
import { baseUrl } from '../../App';
import styles from '../EventOverview/EventOverview.module.css'; // Import css modules stylesheet as styles

const CamperOverview = () => {
    const [options] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9])
    const [quantity, setQuantity] = useState(1)

    const [error, setError] = useState(null)
    const [event, setEvent] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    const handleChange = (event) => {
        setQuantity(event.target.value)
    }
    const handleSubmit = (event) => {
        event.preventDefault()
    }

    const slug = useParams().slug
    const id = useParams().id

    // useHistory instead of this.props.location.state
    const { days, startDate, endDate } = useHistory().location.state

    useEffect(() => {
        fetch(`${baseUrl}/campers/${slug}/`)
            .then(res => res.json())
            .then(
                (result) => {
                    setEvent(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    console.log('setError()', error);
                    setIsLoaded(true);
                }
            )
    }, [slug])

    if (error) {
        return <div className="container">Error: {error.message}</div>
    } else if (!isLoaded) {
        return <></>
    } else {
        const stock = event.stock.find(x => x.id === parseInt(id))
        // Convert STOCK.PRICE from string to int. This should eventually be changed in api.
        // const stockPriceInt = parseInt(stock.price)
        const stockPriceInt = stock.price

        const subTotal = stockPriceInt * days
        const serviceCosts = (stock.service_costs / 100) * subTotal
        const totalPrice = subTotal + serviceCosts

        const startDateConverted = new Date(startDate)
        const endDateConverted = new Date(endDate)
        const a = `${startDateConverted.getDate()}-${(startDateConverted.getMonth() + 1)}-${startDateConverted.getFullYear()}`
        const b = `${endDateConverted.getDate()}-${(endDateConverted.getMonth() + 1)}-${endDateConverted.getFullYear()}`

        return (
            <div className="container mb-5">
                <div className={styles["container-main"]}>

                    <div className={styles["container-reserveringen"]}>

                        <div className={styles["reserveringen-header"]}>
                            <h2 className="font-weight-bold">
                                Reserveer uw parkeerplaats
                            </h2>
                            <hr></hr>
                        </div>

                        <div className={styles["item-b"]}>
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Pick no. of tickets
                                    <select value={quantity} onChange={handleChange} disabled={true}>
                                        {options.map((i, index) =>
                                            // We use index for keys, because the array and its items are static. They will never change.
                                            <option key={index} value={i}>{i}</option>
                                        )}
                                    </select>
                                </label>
                                {/* <input type="submit" value="Submit" /> */}
                            </form>
                            <p className="text-muted">Meer kaarten nodig? Wijzig het aantal hierboven</p>
                        </div>

                        <div className={styles["item-c"]}>
                            <img
                                src={event.cover_image}
                                style={{ width: '100%' }}
                                alt={event.title}>
                            </img>
                        </div>

                        <div className={styles["item-d"]}>
                            <p className="font-weight-bold">
                                {a} t/m {b}
                            </p>
                            <p>&euro;{subTotal.toFixed(2)}</p>
                            <p>{stock.parking_place}</p>
                            <p>
                                {days} dag(en)
                            </p>
                        </div>

                    </div>

                    <div className={styles["container-totaalprijs"]}>
                        <div className={styles["totaalprijs-header"]}>
                            <h2 className="font-weight-bold">
                                Totaalprijs
                            </h2>
                            <hr></hr>
                        </div>
                        <div className={styles["item-f"]}>
                            <p>Subtotaal</p>
                            <p>Servicekosten &amp; Toeristenbelasting</p>
                        </div>
                        <div className={styles["item-g"]}>
                            <p>&euro;{subTotal.toFixed(2)}</p>
                            <p>&euro;{serviceCosts.toFixed(2)}</p>
                        </div>
                        <div className={styles["horizontal-line"]}>
                            <hr></hr>
                        </div>
                        <div className={styles["item-h"]}>
                            <p className="font-weight-bold">Totaalprijs (incl. BTW)</p>
                        </div>
                        <div className={styles["item-i"]}>
                            <p className="font-weight-bold">&euro;{totalPrice.toFixed(2)}</p>
                        </div>
                        <div className={styles["item-j"]}>
                            <Link
                                to={{
                                    pathname: `/checkout/${slug}/${id}`,
                                    state: {
                                        event,
                                        days,
                                        startDate,
                                        endDate,
                                        subTotal,
                                        serviceCosts,
                                    }
                                }}
                            >
                                <button type="button" className={`btn btn-info btn-block ${styles['btn-eventdetails']} btn-lg`}>Boek nu je ticket</button>
                            </Link>
                            <small className="text-muted text-center" style={{ textAlign: 'center' }}>Na boeking ontvang je een bevestiging per mail en een gedetailleerde routebeschrijving</small>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CamperOverview