import React from 'react'
import { baseUrl } from '../../App'
import Events from '../Events/Events'

const Crew = () => {
    return (
        <div>
            {/* <Events eventsUrl={"https://admin.joepark.nl/api/crew-list/"} /> */}
            <Events eventsUrl={`${baseUrl}/crew-list/`} />
        </div>
    )
}

export default Crew