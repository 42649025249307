import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import { baseUrl } from '../../App';
import styles from './EventDetails.module.css'; // Import css modules stylesheet as styles

const EventDetails = () => {
    const slug = useParams().slug

    const [error, setError] = useState(null)
    const [event, setEvent] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    const [selectedDate, setSelectedDate] = useState("")
    const [selectedParking, setSelectedParking] = useState(0)

    useEffect(() => {
        fetch(`${baseUrl}/events/${slug}/`)
            .then(res => res.json())
            .then(
                (result) => {
                    setEvent(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [slug])

    useEffect(() => {
        setSelectedParking(0)
    }, [selectedDate])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value)
    }

    const handleParkingAreaChange = (event) => {
        setSelectedParking(parseInt(event.target.value))
    }

    if (error) {
        return <div className="container">Error: {error.message}</div>
    } else if (!isLoaded) {
        return <></>
    } else {
        // Sorts dates by occurence
        const sortedDates = event.dates.sort((a, b) => {
            // https://stackoverflow.com/questions/10123953/how-to-sort-an-array-by-a-date-property
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.start_date) - new Date(b.start_date)
        })

        // Returns list filtered on previously selected date
        const parkingAreasFilterd = event.stock.filter(parkingArea => parkingArea.date.split(/[T ]/i, 1)[0] === selectedDate.split(/[T ]/i, 1)[0])

        return (
            <div className="container mb-5">

                <div style={{ backgroundColor: 'var(--joepark-grey)' }}>
                    <div className="container" style={{ marginBottom: '130px', maxHeight: '265px' }}>
                        <div className={`jumbotron ${styles['jumbotron-eventdetails']} text-white mb-0`} style={{ backgroundImage: `linear-gradient(to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.4)), url("${event.cover_image}")` }}>
                            <header>
                                <h1 className="display-4 font-weight-bold">
                                    {event.name}
                                </h1>
                            </header>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-4 py-2">
                            <h2 className='font-weight-bold text-left'>Omschrijving</h2>
                            <p className="text-left">{event.description}</p>
                        </div>
                        <div className="col-md-4 py-2">
                            <h2 className='font-weight-bold text-left'>Datum kiezen</h2>
                            {sortedDates.map((sortedDate, index) => {
                                let sumOfTickets = 0
                                // for each showDate of the event -> if the showDate start_date matches one of the sortedDates -> add the quantity of parkingtickets for that showDate to sumOfTickets
                                event.stock.forEach(showDate => {
                                    if (showDate.date.split(/[T ]/i, 1)[0] === sortedDate.start_date.split(/[T ]/i, 1)[0]) {
                                        sumOfTickets = sumOfTickets + showDate.quantity
                                    }
                                })
                                return (
                                    <label className={styles["container-radiobutton"]} key={index}>
                                        <input type="radio" value={sortedDate.start_date} checked={sortedDate.start_date === selectedDate} name="selectedDate" onChange={handleDateChange} />
                                        <div className="btn btn-block">
                                            {new Date(sortedDate.start_date).toLocaleDateString()}
                                            <p className="mb-0 text-muted">
                                                Beschikbare tickets <span className="badge badge-dark">{sumOfTickets}</span>
                                            </p>
                                        </div>
                                    </label>
                                )
                            }
                            )}
                        </div>
                        <div className="col-md-4 py-2">
                            <h2 className='font-weight-bold text-left'>Parkeerlocatie kiezen</h2>
                            {parkingAreasFilterd.map((parkingArea, index) =>
                                <label className={styles["container-radiobutton"]} key={index}>
                                    <input type="radio" value={parkingArea.id} checked={parkingArea.id === selectedParking} name="selectedParkingArea" onChange={handleParkingAreaChange} />
                                    <div className="btn btn-block">
                                        {parkingArea.parking_place} | Vanaf: {new Date(parkingArea.date).getHours()}.{(new Date(parkingArea.date).getMinutes() < 10 ? '0' : '') + new Date(parkingArea.date).getMinutes()} uur
                                        {parkingArea.info !== "" && <><br></br><small>Geldig voor: {parkingArea.info}</small></>}
                                        <p className="mb-0 text-muted">
                                            Beschikbare tickets <span className="badge badge-dark">{parkingArea.quantity}</span>
                                        </p>
                                    </div>
                                </label>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <Link to={`/eventoverview/${slug}/${selectedParking}`}>
                                <button type="button" className={`btn btn-info btn-lg ${styles['btn-eventdetails']}`} disabled={selectedParking === 0}>Boek nu je ticket!</button>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default EventDetails