import React, { useState, useEffect } from 'react'
import Events from '../Events/Events'
// import styles from './Home.module.css' // Import css modules stylesheet as styles
import Venues from '../Venues/Venues'
import WerkenBij from '../WerkenBij/WerkenBij'
import { baseUrl } from '../../App'

const Home = () => {
    const [error, setError] = useState(null)
    const [venues, setVenues] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        fetch(`${baseUrl}/locations/`)
            .then(res => res.json())
            .then(
                (result) => {
                    setVenues(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [])

    if (error) {
        return (
            <div className="container py-5">
                <div className="alert alert-danger" role="alert">
                    <div>Error: {error.message}</div>
                </div>
            </div>
        )
    } else if (!isLoaded) {
        return (
            <></>
        )
    } else {
        return (
            <>
                {/* <div className="bg--grey">
                    <Camper venues={venues}/>
                </div> */}
                <div className="bg--grey">
                    <Events eventsUrl={`${baseUrl}/events-list/`} />
                </div>

                <Venues />

                <div className="bg--grey">
                    <WerkenBij />
                </div>
            </>
        )
    }
}

export default Home