import React, { useEffect } from 'react';

const AlgemeneVoorwaarden = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="container">
            <h1>Algemene Voorwaarden</h1>
            <h3>
                1. Definities
            </h3>
            <p>
                Parkeerfaciliteit:
                De parkeergarage en/of het parkeerterrein met bijbehorende terreinen en ruimten.
            </p>
            <p>
                Joe Park B.V. Prins Hendrikkade 84-85, 1012 AE Amsterdam, kamer van Koophandel nr: 69178100. BTW nummer: NL857769376B01
            </p>
            <p>
                Joe Park B.V. in de hoedanigheid van eigenaar, exploitant of beheerder. Ingeval Joe Park B.V. de parkeerfaciliteit beheert namens een derde-exploitant, dient voor ‘Joe Park B.V.’ te worden gelezen: “Joe Park B.V., in deze optredend namens de exploitant van de parkeerfaciliteit”.
            </p>
            <p>
                Parkeerder: Degene die het voertuig in of op de parkeerfaciliteit brengt.
            </p>
            <p>
                Abonnementhouder:
                De houder van een abonnement dat recht geeft gedurende een overeengekomen periode en onder overeengekomen voorwaarden op gebruik van de parkeerfaciliteit.
            </p>
            <p>
                Parkeerbewijs:
                Het door de parkeerder gekozen en door Joe Park B.V. aangewezen middel – zoals een parkeerticket, creditcard, afstandsbediening, parkeerpas, mobiele applicatie – dat toegang geeft tot de parkeerfaciliteit en aan de hand waarvan Joe Park B.V. het tijdstip van binnenkomst in de parkeerfaciliteit kan vaststellen.
            </p>
            <p>
                Parkeergeld:
                Bedrag dat de parkeerder verschuldigd is voor het gebruik van de parkeerfaciliteit. Voor abonnementhouders bestaat het parkeergeld uit de overeengekomen vergoeding voor het abonnement en (indien van toepassing) een vergoeding voor de geparkeerde uren die vallen buiten het abonnement.
            </p>
            <p>
                Parkeer betaling Systeem:
                Systeem bestaande uit hard- en software welke wordt gebruikt voor toegangscontrole, betalingshandelingen, uitrijdcontrole en het leveren van managementinformatie met betrekking tot de parkeerfaciliteit.
            </p>
            <p>
                Kortparkeertarief:
                Het bij de ingang van de parkeerfaciliteit gemelde te betalen danwel vermelde parkeertarief of vermelde te betalen parkeertarief verkregen middels voorverkoop dat geldt voor de aangegeven duur voor het parkeren in de parkeerfaciliteit.
            </p>
            <h3>
                2. Parkeerovereenkomst en toepasselijke voorwaarden
            </h3>            
            <p>
                1. De toegang tot de parkeerfaciliteit wordt uitsluitend verleend onder toepassing van deze voorwaarden, die deel uitmaken van iedere parkeerovereenkomst gesloten met Joe Park B.V.
            </p>
            <p>
                2. Indien geen gebruik wordt gemaakt van een parkeerbewijs dat behoort bij een
                abonnement, wordt een parkeerovereenkomst geacht tot stand te zijn gekomen door het enkele feit van het houderschap van een parkeerbewijs c.q. door het enkele feit van het gebruik van de parkeerfaciliteit. Bij onenigheid over de vraag of er gebruik wordt of reeds
                is gemaakt van de parkeerfaciliteit, zal bepalend zijn het feit dat de parkeerder zich op
                het tot de parkeerfaciliteit behorende terrein achter de slagboom bevindt of heeft bevonden.
            </p>
            <p>
                3. De in deze algemene voorwaarden genoemde artikelen kunnen tevens worden ingeroepen jegens de parkeerder door de beheerder(s) en eigenaar(s) van de parkeerfaciliteit, voor zover dit niet dezelfde partij(en) is/ zijn als Joe Park B.V. Dit beding is onherroepelijk.
            </p>              
            <h3>
                3. Verplichting JoePark B.V.
            </h3>
            <p>
                Joe Park B.V. stelt aan de parkeerder een willekeurige parkeerplaats voor 1 voertuig in de parkeerfaciliteit ter beschikking, mits er voldoende plaats beschikbaar is. Tot de verplichting van Joe Park B.V. behoort niet de bewaking van het voertuig.
            </p>                          
            <h3>4. Toegangseisen</h3>
            <p>1 (een) voertuig en enkel personen aanwezig in het voertuig zijn slechts bevoegd de parkeerfaciliteit binnen te komen met een geldig parkeerbewijs.</p>
            <p>
                Het in- en uitrijden van voertuigen in/uit de parkeerfaciliteit kan uitsluitend geschieden tijdens de openingstijden. Joe Park B.V. stelt de openingstijden vast en is altijd gerechtigd deze te wijzigen.
            </p>
            <p>Tot de parkeerfaciliteit worden uitsluitend toegelaten personenauto’s en bedrijfsauto’s met een maximale lengte van 4,80 m., een maximale breedte van 1,90m., een maximaal gewicht van 2.800 kg en met een eventueel maximaal bij de ingang aangegeven hoogte. De eventuele bij de ingang vermelde maximale hoogte geldt, tenzij anders aangegeven, voor de rijbaan van de parkeerfaciliteit. Ten aanzien van gedeelten van de parkeerfaciliteit kunnen afwijkende hoogten gelden dan bij de ingang vermeld.</p>
            <p>Het is niet toegestaan met aanhangwagens van welke aard dan ook, hieronder mede begrepen caravans, de parkeerfaciliteit in- of op te rijden.</p>
            <p>De in of op de parkeerfaciliteit geparkeerde voertuigen dienen aan dezelfde voorwaarden te voldoen als de op de openbare weg geparkeerde voertuigen (deel II/WA Verzekering enz.).</p>
            <p>Joe Park B.V. is gerechtigd aan enig voertuig de toegang tot de parkeerfaciliteit te weigeren indien Joe Park B.V. dit met inachtneming van de redelijkheid en billijkheid wenselijk acht. Dit geval zal zich onder andere voordoen indien Joe Park B.V. van oordeel is dat het voertuig gelet op de omvang en/of zwaarte, dan wel door de zaken die daarmee worden vervoerd, aan de omgeving schade
            kan toebrengen in de meest ruime zin. Joe Park B.V. is ook gerechtigd voertuigen die (mede) LPG als brandstof gebruiken de toegang tot de parkeerfaciliteit te weigeren, indien de parkeerfaciliteit daarop niet uitgerust is.
            </p>
            <h3>5. Gebruikersvoorschriften</h3>
            <p>
                De parkeerder betreedt en maakt gebruik van de parkeerfaciliteit geheel voor eigen risico. De parkeerder dient te allen tijde de nodige voorzichtigheid te betrachten, in het bijzonder ook voor wat betreft de hoogte van de parkeerfaciliteit buiten de rijbanen.
            </p>
            <p>
                De parkeerder is verplicht de aanwijzingen van Joe Park B.V. ( waaronder aangegeven bewegwijzering en rijrichting) en het door haar aangesteld personeel op te volgen.
            </p>
            <p>In de parkeerfaciliteit geldt een maximumsnelheid van 5 kilometer per uur (stapvoets).</p>
            <p>Gedurende de aanwezigheid op het terrein van de parkeerfaciliteit dient de parkeerder zich
            redelijk en sociaal te gedragen en dient het voertuig te voldoen aan de bepalingen van de Wegenverkeerswet, de nadere regels krachtens deze wet gesteld, het Reglement Verkeersregels en Verkeerstekens en de daarbij behorende bijlagen, als ook de nadere regels krachtens dit reglement gesteld.
            </p>
            <p>De parkeerder dient het voertuig te parkeren op de aangegeven plaatsen en zich zodanig te gedragen dat het verkeer in en/of bij de parkeerfaciliteit niet wordt gehinderd en de veiligheid niet in gevaar wordt gebracht.</p>
            <p>Het is verboden om:</p>
            <ul>
                <li>de parkeerfaciliteit te gebruiken voor andere doeleinden dan voor het parkeren van voertuigen;</li>
                <li>
                    in, op of bij de parkeerfaciliteit goederen of diensten aan te bieden, te verkopen, te verhuren of uit te delen;
                </li>
                <li>op of aan de parkeerfaciliteit reclame te maken;</li>
                <li>in de parkeerfaciliteit te roken of vuur te maken;</li>
                <li>
                    langer in het voertuig te verblijven dan gedurende de tijd die nodig is om het betreffende voertuig te parkeren. Nadat het voertuig is geparkeerd dient het te worden afgesloten, de lichten te worden gedoofd, de motor uit te worden gezet en dienen de inzittenden het voertuig en de parkeerfaciliteit te verlaten;
                </li>
                <li>in of op de parkeerfaciliteit reparaties aan het voertuig of andere werkzaamheden uit te voeren of te doen uitvoeren, tenzij daartoe uitdrukkelijk toestemming door of namens Joe Park B.V. is verleend;</li>
                <li>afval/vuilnis en/of vloeistoffen in de meest ruime zin des woords in, op of bij de parkeerfaciliteit achter te laten danwel te dumpen;</li>
                <li>voor voetgangers de hellingbanen, in- en uitritten voor voertuigen te gebruiken.</li>
            </ul>
            <p>
                Het personeel van Joe Park B.V. is gerechtigd, indien dit naar het oordeel van dit personeel noodzakelijk is, voertuigen en/of personen binnen de parkeerfaciliteit te verplaatsen en/
                of uit de parkeerfaciliteit te (doen) verwijderen, zonder dat dit tot enige aansprakelijkheid
                voor Joe Park B.V. of het personeel kan leiden. Het personeel zal bij het beoordelen van de noodzaak tot verplaatsen en/of verwijderen de redelijkheid en zorgvuldigheid in acht nemen.
            </p>
            <p>
                De maximale aaneengesloten parkeertijd voor niet- abonnementhouders bedraagt de duur van het evenement op die dag. Na afloop van deze maximale termijn is de gebruiker van de parkeerfaciliteit naast en boven het normaal geldende parkeertarief een boete verschuldigd van €50,– per dag,
                voor elke dag dat na het verstrijken van de maximale termijn nog gebruik wordt gemaakt van de parkeerfaciliteit. Dit zonder dat enige ingebrekestelling is vereist en onverkort het recht van Joe Park B.V om daarnaast en daarboven schadevergoeding te vorderen.
            </p>
            <p>
                De parkeerder en zijn voertuig dienen de parkeerfaciliteit uitsluitend te verlaten met gebruikmaking van een geldig, door Joe Park B.V. geaccepteerd parkeerbewijs of middels aanwijzigingen van
                het personeel van Joe Park B.V. Het zonder gebruikmaking van een geldig door Joe Park B.V. geaccepteerd parkeerbewijs of middel van de parkeerfaciliteit is onder geen beding toegestaan. De parkeerder is in dat geval het door Joe Park B.V. voor de betreffende parkeerfaciliteit vastgestelde tarief “geen kaart” verschuldigd (afhankelijk van de parkeerfaciliteit bedraagt dit éénmaal, tweemaal of driemaal het geldende tarief), vermeerderd met een bedrag aan aanvullende schadevergoeding ad €300,- en zulks onverminderd de rechten van Joe Park B.V. tot het vorderen van overige daadwerkelijk geleden (gevolg-)schade. Het hiervoor genoemde tarief “geen kaart” laat onverlet
                het recht van Joe Park B.V. om de parkeerder de werkelijke parkeerkosten in rekening te brengen mochten die hoger zijn dan het tarief “verloren kaart”.
            </p>
            <h3>6. Parkeergeld en betaling</h3>
            <p>Voor het gebruik van de parkeerfaciliteit is de parkeerder parkeergeld verschuldigd. Behalve
            als sprake is van een abonnement, zal het parkeergeld worden berekend aan de hand van het parkeerbewijs volgens de door Joe Park B.V. vastgestelde tarieven op basis van het tijdsbestek en evenement dat het voertuig van de parkeerder in de parkeerfaciliteit aanwezig zal zijn.
            </p>
            <p>
                Het parkeergeld dient te worden voldaan voordat de parkeerder met zijn voertuig de parkeerfaciliteit betreedt, tenzij een andere regeling getroffen is.
            </p>
            <p>Het zonder voorafgaande betaling van het verschuldigde parkeergeld en met het voertuig verlaten van de parkeerfaciliteit, bijvoorbeeld door middel van het zogenoemde “treintje rijden” waarbij de parkeerder direct achter zijn voorganger onder de slagboom doorrijdt danwel, is onder geen beding toegestaan. De parkeerder is in dat geval het door Joe Park B.V. voor de betreffende parkeerfaciliteit vastgestelde tarief “geen kaart” verschuldigd (afhankelijk van de parkeerfaciliteit bedraagt dit éénmaal, tweemaal of driemaal het geldende tarief), vermeerderd met een bedrag aan aanvullende schadevergoeding ad €300,- en zulks onverminderd de rechten van Joe Park B.V. tot het vorderen van overige daadwerkelijk geleden (gevolg-)schade. Het hiervoor genoemde tarief “geen kaart” laat onverlet het recht van Joe Park B.V. om de parkeerder de werkelijke parkeerkosten in rekening te brengen mochten die hoger zijn dan het tarief “geen kaart”.</p>
            <p>Na afloop van de beschikbare tijd voor het gebruik maken van de parkeerlocatie dient een parkeerder zo snel mogelijk de parkeerfaciliteit met zijn voertuig te verlaten.</p>
            <p>In geval van verlies of het ontbreken van het parkeerbewijs, is de parkeerder het door Joe Park B.V. voor de betreffende parkeerfaciliteit vastgestelde tarief “geen kaart” verschuldigd (afhankelijk van de parkeerfaciliteit bedraagt dit éénmaal, tweemaal of driemaal het geldende dagtarief). De parkeerder dient dit bedrag vóór het verlaten van de parkeerfaciliteit te voldoen. Het hiervoor genoemde tarief “geen kaart” laat onverlet het recht van Joe Park B.V. om de parkeerder de werkelijke parkeerkosten in rekening te brengen mochten die hoger zijn dan het tarief “geen kaart”. De bewijslast met betrekking tot het in bezit zijnde van een geldige parkeerkaart berust bij de parkeerder.</p>
            <p>Joe Park B.V. is vrij in het aanpassen van alle parkeertarieven.</p>
            <h3>7. Aansprakelijkheid</h3>
            <p>
                Tot de verplichting van Joe Park B.V. behoort niet de bewaking van het voertuig. Joe Park B.V. is niet aansprakelijk voor beschadiging, diefstal, verlies van het voertuig en de andere eigendommen van parkeerder.
            </p>
            <p>Joe Park B.V. is niet aansprakelijk voor welke schade dan ook aan de eigendommen van de parkeerder, alsmede ter zake van lichamelijk letsel en/of enige andere schade, direct of indirect veroorzaakt door of ten gevolge van het gebruik van de parkeerfaciliteit, tenzij de schade direct is veroorzaakt door of vanwege Joe Park B.V. en/of het personeel aanwezig in de parkeerfaciliteit en deze aansprakelijkheid niet in enig ander artikel van deze algemene voorwaarden en de overeenkomst met parkeerder is uitgesloten.</p>
            <p>Joe Park B.V. is niet gehouden schade te vergoeden indien sprake is van overmacht. Onder overmacht zal in ieder geval worden gerekend een van de wil van Joe Park B.V. onafhankelijke omstandigheid als gevolg waarvan de nakoming van de verplichtingen uit de overeenkomst redelijkerwijs door de parkeerder niet meer van Joe Park B.V. kan worden verlangd. Hieronder worden in ieder geval begrepen werkstakingen, brand, overheidsmaatregelen, bedrijfsstoringen, slechte weersomstandigheden zoals hevige regen, ijzel of sneeuwval, alsmede een tekortkoming in de nakoming door derden.</p>
            <p>De parkeerder is aansprakelijk voor alle schade die door hem is veroorzaakt. Schade door de parkeerder veroorzaakt aan de parkeerfaciliteit of de daarbij behorende apparatuur en installaties dient ter plaatse te worden vergoed tenzij, naar het oordeel van Joe Park B.V., de parkeerder voldoende zekerheid kan bieden dat de schade wordt vergoed. Voor de vaststelling van de hoogte van de vergoeding zal het rapport van een door Joe Park B.V. aangewezen deskundige beslissend zijn. Kosten van het opstellen van het rapport zijn voor rekening van de parkeerder.</p>
            <p>Onder ‘parkeerder’ wordt in dit artikel mede verstaan andere inzittenden van zijn voertuig</p>
            <h3>8. Niet-nakoming</h3>
            <p>Indien de parkeerder tekortschiet in de nakoming van enige verplichting welke ingevolge de wet, de plaatselijke verordeningen en gebruiken en/of de met hem gesloten overeenkomst inclusief de daarop van toepassing zijnde voorwaarden op hem rust, is de parkeerder gehouden aan Joe Park B.V. alle schade te vergoeden, door deze geleden en te lijden als gevolg van bovengenoemde fout, nalatigheid of enig ander in gebreke blijven.</p>
            <p>Indien Joe Park B.V. genoodzaakt is een sommatie, ingebrekestelling of ander exploot aan de parkeerder te doen uitbrengen of in geval van noodzakelijke procedures tegen de parkeerder, is de parkeerder verplicht alle daarvoor gemaakte kosten, waaronder de kosten van rechtskundige bijstand, zowel in als buiten rechte, aan Joe Park B.V. te vergoeden.</p>
            <p>
                Joe Park B.V. is te allen tijde gerechtigd het voertuig van de parkeerder onder zich te houden en/ of daartoe geëigende maatregelen te treffen, zoals het aanbrengen van een wielklem, zolang de parkeerder niet al hetgeen hij verschuldigd is aan Joe Park B.V., heeft voldaan.
            </p>
            <p>Joe Park B.V. is tevens gerechtigd een parkeerder de toegang tijdelijk of permanent te ontzeggen indien deze de regels neergelegd in deze voorwaarden niet naleeft.</p>
            <p>Joe Park B.V. is gerechtigd een achtergelaten auto zonder nadere ingebrekestelling en voor rekening en risico van de parkeerder te verplaatsen en op te slaan (naar keuze van Joe Park
            B.V.), zowel binnen als buiten de parkeerfaciliteit. Een auto welke 28 aaneengesloten dagen in de parkeerfaciliteit geparkeerd heeft gestaan, zal als achtergelaten worden gekwalificeerd. Een auto mag door Joe Park B.V. zonder nadere ingebrekestelling worden verkocht of worden vernietigd (naar keuze van Joe Park B.V.) indien deze meer dan 28 dagen als achtergelaten wordt gekwalificeerd en de gehele dan openstaande schuld aan de parkeerder tussentijds niet is voldaan. Na verkoop van
            de auto, zal de parkeerder van de auto alleen van zijn schuld aan Joe Park B.V. bevrijd zijn, als de opbrengst van de auto hoger is dan deze schuld. Het eventuele saldo zal aan de parkeerder worden uitgekeerd.
            </p>
            <h3>9. Privacy</h3>
            <p>In de parkeerfaciliteit, bij o.a. de in-/uitrit, kan ter bestrijding van diefstal en vernieling videoregistratie plaatsvinden. Deze beelden worden na 24 uur gewist, behoudens indien Joe Park B.V. die moet afstaan aan het bevoegd gezag.</p>
            <p>Bij het in-/oprijden van de parkeerfaciliteit kan – indien aangegeven – door Joe Park B.V. gebruik worden gemaakt van kentekenherkenning. In dat geval zal het kenteken bij het in-/ oprijden worden geregistreerd in het Parkeer betaling Systeem en – indien van toepassing – worden afgedrukt op het parkeerticket. Het kenteken wordt geregistreerd en afgedrukt op het parkeerticket ter bestrijding van fraude, in het bijzonder ter bestrijding van fraude met (verloren) parkeerbewijzen en van diefstal. Het kenteken wordt automatisch verwijderd uit het Parkeer betaling Systeem, uiterlijk 24 uur nadat het voertuig de parkeerfaciliteit heeft verlaten en het parkeergeld is voldaan. Kentekens worden niet verstrekt aan derden, tenzij Joe Park B.V. daartoe een wettelijke verplichting heeft.</p>
            <h3>10. Overige bepalingen</h3>
            <p>Afspraken met personeelsleden van Joe Park B.V. binden deze laatste niet, tenzij deze
            door een bevoegde vertegenwoordiger van Joe Park B.V. schriftelijk zijn bevestigd. Als personeelsleden zijn in dit verband te beschouwen alle werknemers en medewerkers die geen vertegenwoordigingsbevoegdheid hebben.
            </p>
            <p>Voor zover enig beding in de tussen Joe Park B.V. en de parkeerder gesloten overeenkomst waaronder begrepen deze algemene voorwaarden, nietig of vernietigbaar of niet van toepassing is, dient dit beding te worden geconverteerd in een zodanig beding dat zoveel mogelijk recht doet aan de strekking van het nietige, vernietigde of buiten toepassing gelaten beding.</p>
            <p>Joe Park B.V. is gerechtigd deze voorwaarden te allen tijde te wijzigen.</p>
            <p>Op deze overeenkomst is Nederlands recht van toepassing.</p>         
        </div>
    )
}

export default AlgemeneVoorwaarden