import React from 'react'
import { useHistory } from "react-router-dom";

const CheckoutComplete = () => {
    const history = useHistory()
    if (history.location.search.includes('redirect_status=failed'))
    {
        return (
            <div className="container py-5">
                <div className="alert alert-danger" role="alert">
                    <div>Betaling geannuleerd</div>
                </div>
                <p>
                    Helaas is er iets misgegaan met de betaling. De parkeertickets zijn dus niet gereserveerd. Probeer het opnieuw.
                </p>
                <p>
                    Heeft u vragen? Neem dan gerust contact met ons op via <a href="https://api.whatsapp.com/send?phone=31687878080&text=&source=&data=&app_absent="><strong>WhatsApp</strong></a>, <a href="https://www.facebook.com/Joe-Park-483680252115786/"><strong>Facebook</strong></a>, <a href="https://www.instagram.com/joeparknl/"><strong>Instagram</strong></a>, <a href="https://twitter.com/joeparknl"><strong>Twitter</strong></a> of per <a href="info@joepark.nl"><strong>mail</strong></a>.
                </p>
            </div>
        )
    }
    return (
        <div className="container py-5">
            <div class="alert alert-success" role="alert">
                Betaling voltooid
            </div>
            <p>
                Hartelijk dank! Wij hebben jouw betaling ontvangen.
            </p>
            <p>
                Je ontvangt het parkeerticket automatisch per e-mail. Controleer voor de zekerheid de spambox.
            </p>
            <p>
                Heeft u vragen? Neem dan gerust contact met ons op via <a href="https://api.whatsapp.com/send?phone=31687878080&text=&source=&data=&app_absent="><strong>WhatsApp</strong></a>, <a href="https://www.facebook.com/Joe-Park-483680252115786/"><strong>Facebook</strong></a>, <a href="https://www.instagram.com/joeparknl/"><strong>Instagram</strong></a>, <a href="https://twitter.com/joeparknl"><strong>Twitter</strong></a> of per <a href="info@joepark.nl"><strong>mail</strong></a>.
            </p>
        </div>
    )
}

export default CheckoutComplete