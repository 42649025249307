import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CamperCheckoutForm from './CamperCheckoutForm/CamperCheckoutForm';
import EventCheckoutForm from './EventCheckoutForm/EventCheckoutForm';
import { baseUrl } from '../App';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';

const LinkButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
`

const InjectedCheckoutForm = (props) => {
    let history = useHistory()

    if (props.location.state === undefined)
    {
        return (
            <div className="container py-5">
                <div className="alert alert-danger" role="alert">
                    <div>Error: props.location.state === undefined</div>
                </div>
                <p>
                    Oeps. Er is iets fout gegaan tijdens het bestelprocess. Druk op <LinkButton onClick={() => { history.push('/') }} className={`alert-link`}>hier</LinkButton> om het bestelprocess opnieuw te starten.
                </p>
                <p>
                    Heeft u vragen? Neem dan gerust contact met ons op via <a href="https://api.whatsapp.com/send?phone=31687878080&text=&source=&data=&app_absent="><strong>WhatsApp</strong></a>, <a href="https://www.facebook.com/Joe-Park-483680252115786/"><strong>Facebook</strong></a>, <a href="https://www.instagram.com/joeparknl/"><strong>Instagram</strong></a>, <a href="https://twitter.com/joeparknl"><strong>Twitter</strong></a> of per <a href="info@joepark.nl"><strong>mail</strong></a>.
                </p>
            </div>
        )
    }
    if (props.location.state.event.url.includes(`${baseUrl}/events/`)) {
        return (
            <ElementsConsumer>
                {({ stripe, elements }) => (
                    <EventCheckoutForm stripe={stripe} elements={elements} props={props} />
                )}
            </ElementsConsumer>
        )
    }
    if (props.location.state.event.url.includes(`${baseUrl}/campers/`)) {
        return (
            <ElementsConsumer>
                {({ stripe, elements }) => (
                    <CamperCheckoutForm stripe={stripe} elements={elements} props={props} />
                )}
            </ElementsConsumer>
        )
    }
    return (
        <div className="container py-5">
            <div className="alert alert-danger" role="alert">
                <div>Error: props.location.state.event.url doesn't include</div>
            </div>
            <p>
                Oeps. Er is iets fout gegaan. Druk op <button onClick={() => { history.push('/') }} className={`alert-link`}>hier</button> om het bestelprocess opnieuw te starten.
            </p>
            <p>
                Heeft u vragen? Neem dan gerust contact met ons op via <a href="https://api.whatsapp.com/send?phone=31687878080&text=&source=&data=&app_absent="><strong>WhatsApp</strong></a>, <a href="https://www.facebook.com/Joe-Park-483680252115786/"><strong>Facebook</strong></a>, <a href="https://www.instagram.com/joeparknl/"><strong>Instagram</strong></a>, <a href="https://twitter.com/joeparknl"><strong>Twitter</strong></a> of per <a href="info@joepark.nl"><strong>mail</strong></a>.
            </p>
        </div>
    )
}

export default InjectedCheckoutForm